import * as actionCreators from "./actionCreators"
import {combineReducers} from "redux";
import { CustomerInfo, Product } from "../domain/interfaces";

const error = (error: string = "", action: actionCreators.Actions) => {
    if (action.type == 'BASKET_ERROR') {
        console.log(action.payload)
        return action.payload
    } 
    return error
}

const loading = (loading: boolean = false, action: actionCreators.Actions) => {
    if (action.type == 'LOADING_BASKET') {
        return action.payload
    } 
    return loading
}

const display = (display: boolean = false, action: actionCreators.Actions) => {
    if (action.type == 'DISPLAY_BASKET') {
        return action.payload
    }
    return display
}

const products = (products: Product[] = [], action: actionCreators.Actions) => {
    if (action.type == 'BASKET_FETCHED') {
        return action.payload.products.filter((p: any) => p.quantity > 0)
    }
    return products
}

const priceTotalFormated = (price: string = "", action: actionCreators.Actions) => {
    if (action.type == 'BASKET_FETCHED') {
        return action.payload.priceTotalFormated
    }
    return price
}

const priceTotalDeliveryFormated = (price: string = "", action: actionCreators.Actions) => {
    if (action.type == 'BASKET_FETCHED') {
        return action.payload.priceTotalDeliveryFormated
    }
    return price
}

const outOfStock = (value: boolean = false, action: actionCreators.Actions) => {
    if (action.type == 'BASKET_FETCHED') {
        return action.payload.products.filter(product => product.quantity > 0).map(product => product.product.stockAvailable).filter(stock => stock <= 0).length > 0
    }
    return value
}


export default combineReducers({
    error,
    loading,
    display,
    priceTotalFormated,
    priceTotalDeliveryFormated,
    products,
    outOfStock
});
