import React from 'react';
import { Provider } from 'react-redux';
import { httpApiGateway } from '../adapters/secondary/httpEshopApi';
import { refreshBasket } from '../usecases/basket';
import { configureStore } from './configureStore';
import { navigate } from 'gatsby'

const store = configureStore({ 
  apiGateways: new httpApiGateway(),
  navigate,
  mountPaymentGateway(checkoutdata, onload, oncomplete) {
    console.log('on mount sumup card')
    setTimeout( () => window.SumUpCard.mount({
      checkoutId: checkoutdata,
      locale: 'fr-FR',
      onResponse: (type, body) => {
        console.log(type, body)
        if (type === 'error' || type === 'success') {
          oncomplete()
        }
      },
      onLoad: onload
    }), 2000)
    return () => {
      let widget = document.getElementById('sumup-card')
      if (widget) {
        widget.style.width = null;
        widget.style.height = null;
        if (widget.children.length > 0) widget.children[0].remove()
      }
    } 
  }
})
store.dispatch(refreshBasket)

export default ({ element }) => (
  <Provider store={store}>{element}</Provider>
);