import {CatalogueApi, CartApi, CustomerApi, AuthApi, RessourceApi, Configuration} from "../../../eshop-api-client"
import { BlogPost, CategoryTree, HomeBanner, HomeCarousel, Product, StaticContext, Footer, ShopContact, Basket, AccountCreate, AccountLogin, DeliveryAddress, Country, DeliveryOptions, CustomerInfo } from "../../domain/interfaces"
import { ApiGateway } from "./EshopApi.interface"
import Cookies from 'js-cookie'

//const config = new Configuration({ basePath: process.env.ESHOP_API || 'https://api.chamantini.re'  })
const config = new Configuration({ basePath: process.env.ESHOP_API || 'https://api.chamantini.re'  })

interface Category {
    name: string,
    description: string,
    subCategories: Category[]
}

export class httpApiGateway implements ApiGateway {

    get apiConfiguration () {
      return new Configuration({
        basePath: process.env.ESHOP_API || 'https://api.chamantini.re'   ,
        baseOptions : {
          headers: {'Authorization': 'Bearer '+Cookies.get('jwt-token')}
        }
      })
    }

    get CatalogueApi () {
      return new CatalogueApi(this.apiConfiguration)
    }

    get CartApi () {
      return new CartApi(this.apiConfiguration)
    }

    get CustomerApi () {
      return new CustomerApi(this.apiConfiguration)
    }

    get AuthApi () {
      return new AuthApi(this.apiConfiguration)
    }

    get RessourceApi () {
      return new RessourceApi(this.apiConfiguration)
    }

    async retrieveStaticContext(): Promise<StaticContext> {

        // chache key should be invalidate when necessary 
        // const cachekey = 'chamantini-szKaLuLYjr'
        // const cachekey = 'chamantini-szR435aLuLYjr'
        // const cachekey = 'chamantini-szR435a34545LYjr'
        // const cachekey = 'chamantini-szR435a3434424524524'
        // let data = typeof window !== 'undefined' && (sessionStorage ? sessionStorage.getItem(cachekey) : null)

        // if (data) return JSON.parse(data)

        const [
          categories,
          homeCarousels,
          homeBanners,
          favoriteProducts,
          newProducts,
          blogPosts,
          footer,
          contact
        ] = await Promise.all([
            this.retrieveCategories(),
            this.retrieveHomeCarousel(),
            this.retrieveHomeBanners(),
            this.retrieveFavoritesProducts(),
            this.retrieveNewProducts(),
            this.retrieveBlogPosts(),
            this.retrieveFooter(),
            this.retrieveShopContact()
        ])

        // typeof window !== 'undefined' && sessionStorage.setItem(cachekey, JSON.stringify({
        //     categories,
        //     homeCarousels,
        //     homeBanners,
        //     favoriteProducts,
        //     newProducts,
        //     blogPosts,
        //     footer,
        //     contact
        // }))

        return {
            categories,
            homeCarousels,
            homeBanners,
            favoriteProducts,
            newProducts,
            blogPosts,
            footer,
            contact
        }
    }

    async retrieveCategories(): Promise<CategoryTree> {
        const categories = await this.CatalogueApi.catalogueControllerGetCategories()
        categories.data = categories.data.filter(d => d.id == 1)
        categories.data[0].subCategories = categories.data[0].subCategories.filter((c: any) => c.name != 'coup de coeur')
        categories.data[0].subCategories = categories.data[0].subCategories.filter((c: any) => c.name != 'nouvelles collections')
        return categories.data.filter(d => d.id == 1).map(mapCategory)[0]
    }

    async retrieveCategory(slug: string): Promise<CategoryTree> {
      const category = await this.CatalogueApi.catalogueControllerGetCategory(slug)
      return mapCategory(category.data)
    }

    async retrieveHomeCarousel(): Promise<HomeCarousel[]> {
        return [{
            id: 1,
            imgUrl: '/chamantini/photos/cover_picture_4@lg.png',
            imgUrlLower: '/chamantini/photos/cover_picture_4@md.png',
            htmlBanner : `
              <div class="w-full flex flex-row h-64 md:h-128">
                <div class="w-full md:w-1/2 text-center mt-2 md:mt-20 md:pr-10"> 
                    <div class="hidden md:block text-4xl font-bold pt-10"> Perle de papier </div>
                    <div class="hidden md:block text-md pt-2"> Des bijoux uniques </div>
                    <div class="mt-20 pt-10 md:pt-0 md:mt-10">
                      <button class="text-brand-primary bg-white hover:text-white py-2 px-4 rounded-md font-black">
                        <span class="md:hidden"> Bijoux en perle de papier </span>
                        <span class="hidden md:block"> Découvrir les collections </span>
                      </button>
                    </div>
                </div>
              </div>
            `
          }
        ]
    }

    async retrieveHomeBanners(): Promise<HomeBanner[]> {
        return [
            {
              id: 1,
              title: "Bijoux & Parures",
              subtitle: 'Get New Collection At 40% Off',
              buttonLabel: 'Découvrir',
              imgUrl: '/chamantini/photos/product_6@md.jpg'
            },
            {
              id: 2,
              title: 'Tabliers',
              subtitle: 'Get Upto At 50% Off',
              buttonLabel: 'Découvrir',
              imgUrl: '/chamantini/photos/product_7@md.jpg'
            },
            {
              id: 3,
              title: 'Création sur mesure',
              subtitle: 'Products With Lowest Price.',
              buttonLabel: 'Contactez-moi',
              imgUrl: '/chamantini/photos/surmesure@md.jpeg'
            },
          ]
    }

    async retrieveProduct(productSlug: string): Promise<Product | null> {
      const product = await this.CatalogueApi.catalogueControllerGetProduct(productSlug)
      if(!product.data) return null
      return mapProduct(product.data)
    }

    async retrieveFavoritesProducts(): Promise<Product[]> {
      return this.retrieveProducts(7)
    }

    async retrieveNewProducts(): Promise<Product[]> {
      return this.retrieveProducts(8)
    }

    async retrieveProducts(categoryId: number): Promise<Product[]> {
      const products = await this.CatalogueApi.catalogueControllerGetProducts(categoryId.toString())
      return products.data.map(mapProduct).sort((a, b) => b.id - a.id)
    }

    async retrieveRecentProducts(): Promise<Product[]> {
      const products = await this.CatalogueApi.catalogueControllerGetLastProducts()
      return products.data.map(mapProduct)
    }

    async retrieveBlogPost(postSlug: string): Promise<BlogPost | null> {
      let posts = this.retrieveBlogPosts()
      let post = (await posts).find(post => post.id === parseInt(postSlug))
      return post || null
    }

    async retrieveBlogPosts(): Promise<BlogPost[]> {
      return [
          {
            id: 1,
            slug: '1-chamantini-creatrice-de-bijoux-reunion',
            imgUrl: '/chamantini/photos/blog_0@md.jpg',
            title: 'Qui suis-je ?',
            preview: 'Je suis créatrice de bijoux en papier et aussi ..',
            date: '18',
            month: 'décembre',
            year: '2020',
            publishDateFormated: 'Publié le 18 décembre 2020',
            articleHTML: `
                <h2> 
                    Je m’appelle Corinne et je suis Créatrice autodidacte de CHAMANTINI’S PAPER BEADS. Originaire de l’île de la Réunion, je suis également maman de 4 filles, pour lesquelles je crée des vêtements, divers accessoires et bijoux, à base de matériaux neufs ou recyclés depuis leur plus jeune âge. 
                </h2>

                <h4>    
                    Ma passion transmise de génération en génération 
                </h4>

                <p>
                    Ma passion pour la couture me vient de ma mère et de ma grand-mère qui me confectionnaient des vêtements. 
                    J’ai appris avec elles les bases de la couture. Et de fil en aiguille, avec de la pratique et en faisant quelques bêtises ( car on apprend beaucoup de ses erreurs), je me suis perfectionnée.
                </p>


                <h4>    
                    Lier passion à métier
                </h4>

                <p>
                    Ce n’est que lorsque mes filles sont devenues indépendantes, voire adultes pour certaines, que je me suis lancée dans cette aventure qui est de faire connaître ma passion au travers de mes créations. J’ai donc ouvert mon entreprise en 2016. 
                </p>


                <h3>    
                    Ecologie et art
                </h3>

                <h4>    
                    Des perles oui, mais pourquoi le papier ? 
                </h4>

                <p>
                    J’aime son odeur, ses couleurs, sa texture. J’aime le griffonner, le froisser, le couper et essayer toutes sortes d'expériences. 
                </p>

                <p>
                    Utiliser du papier pour en faire des perles contribue à réduire les déchets, je reste ainsi dans l’état d’esprit du “rien n’est perdu” (rien ne se jette).
                </p>

                <h4>    
                    Du papier mais pas que !  
                </h4>

                <p>
                    Je recycle aussi divers tissus ( jeans …) et d’autres matières en fonction de ce qui me passe sous les mains. 
                </p>

                <p>
                    De plus, j’aime le concept d’utiliser des matières du quotidien et de les transformer en un beau bijoux. Cela me permet de m’exprimer en tant qu’artisan. Et puis voir mes bijoux portés me comble de joie. 
                </p>

                <h4>    
                    Des bijoux faits avec amour ♥️
                </h4>

                <p>
                    L’artisanat est un métier qui demande beaucoup de temps, de patience, de rigueur, de dextérité, de concentration et mes outils sont mes mains. 
                    Par conséquent, je ne peux pas rivaliser avec les grandes enseignes qui fabriquent en série et je ne compte pas mes heures passées à créer, mais dans mes créations vous y retrouverez mon amour du travail bien fait, ma passion, mon grain de folie …. 
                    
                    Trêve de bavardage je vous laisse découvrir mon univers et mes créations 
                    N’hésitez pas à me contacter si vous voulez en savoir plus. 

                    <br/>
                    <br/>

                    <i>A Bientôt</i>
                </p>
            `
        },
        {
            id: 2,
            slug: '1-fabriquer-bijoux-en-papier',
            imgUrl: '/chamantini/photos/blog_1@md.jpg',
            title: 'Comment sont fabriquées les perles de papier ?',
            preview: 'Toutes les semaines dans nos boîtes aux lettres ..',
            date: '18',
            month: 'décembre',
            year: '2020',
            publishDateFormated: 'Publié le 18 décembre 2020',
            articleHTML: `
              <h3> De pub à des bijoux uniques: </h3>
              <p> Toutes les semaines dans nos boîtes aux lettres nous recevons diverses publicités, et elles finissent dans la poubelle. </p>
              <p> Moi je récupère ces prospectus , je fais un premier tri en fonction de la couleur et de la qualité du papier. </p>
              <p> Ensuite, je les découpe en bandes, je fais quelques essais pour avoir un aperçu du rendu. Si cela me convient, je continue la découpe. Cette étape terminée, je roule à la main ces bandes. (Je roule de 1 à 40 bandes de papier ensemble pour avoir des formes de perles différentes.) </p>
              <p> J'utilise également d'autres papiers comme le color factory, du papier artisanal …. </p>
              <p> Une fois les bandes roulées, elles forment des perles de tailles, formes, couleurs différentes. Je les trempe ensuite dans 2 produits différents pour les rendre imperméables et durables dans le temps. Ce sont des produits non toxiques et non allergènes. Puis,  je les laisse sécher (2 à 4 jours). </p>
              <p> Une fois les perles en papiers séchées, je les tri les par taille plus ou moins égale pour qu'elles s'harmonisent en fonction du bijou à créer. </p>
              <p> Enfin, je crée mes bijoux. </p>
              <p> En tout, il me faut 1 à 2 semaines de travail pour avoir un bijou fini. </p>
              <p> Chaque perle est unique par conséquent chaque bijou l'est aussi. </p>
              <p> Peut être que vous avez vu dans mon article “QUI SUIS JE” que j'aime également coudre.  Je couds vêtements, tabliers ou divers accessoires et par conséquent, j'ai des chutes de tissu. Et de ses chutes, je fabrique des bandeaux, des bracelets, etc… Rien n’est perdu ;)  </p>
            `
        }
      ]
    }

    async retrieveFooter(): Promise<Footer> {
      return {
        group1: {
            label: 'Produits',
            links: [
                {link:'/category/28-tabliers', label: 'Tablier'},
                {link:'/category/15-colliers', label: 'Colliers'},
                {link:'/category/19-boucles-doreilles', label: "Boucles d'oreilles"},
                {link:'/category/16-bracelets', label: 'Bracelets'},
                {link:'/category/17-parures', label: 'Parures'},
                {link:'/category/18-bijoux-de-chevilles', label: 'Bijoux de chevilles'}
            ]
        },
        group2: {
            label: 'Informations légales',
            links: [
                {link:'/cgv', label: 'Conditions générales de ventes'},
                {link:'/livraison', label: 'Livraison'},
            ]
        },
        group3: {
            label: 'Votre Compte',
            links: [
                {link:'/account', label: 'Mes Commandes'},
            ]
        }
    }
  }

  async retrieveShopContact(): Promise<ShopContact> {
    return {
      phoneNumber: '+262 693 94 91 98',
      emailAddress: 'coco.chamantini@gmail.com',
      address: {
        name: "CHAMANTINI'S PAPER BEADS",
        line1: '97430 LE TAMPON  - ILE DE LA REUNION ',
        line2: '97430 Le Tampon'
      }
    }
  }

  async getBasket(): Promise<Basket> {
    let logged = await this.imLogged()
    if (!logged) return await this.getGuestBasket()
    else return await this.getCustomerBasket()
  }

  async getBasketById(cartId: number): Promise<Basket>{
    const basket = await this.CustomerApi.customerControllerGetCustomerCartId(''+cartId)
      return mapBasket(basket.data)
  }

  async getGuestBasket(): Promise<Basket> { 
    const basketToken = Cookies.get('basket-token')

    if (!basketToken) {
      return {
        priceTotalFormated: "",
        priceTotalDeliveryFormated: "",
        products: [],
      }
    } else {
      const basket = await this.CartApi.cartControllerGetCart(basketToken)
      return mapBasket(basket.data)
    }
    
  }

  async getCustomerBasket(): Promise<Basket> {
      const basket = await this.CustomerApi.customerControllerGetCustomerCart()
      return mapBasket(basket.data)
  }

  async addProductToBasket(productId: number, quantity: number): Promise<void> {
    let logged = await this.imLogged()
    if (!logged) return await this.addProductToGuestBasket(productId, quantity)
    else return await this.addProductToCustomerBasket(productId, quantity)
  }

  async addProductToGuestBasket(productId: number, quantity: number): Promise<void> {
    const client = new CartApi(config)
    
    let basketToken = Cookies.get('basket-token')
    if (!basketToken) {
      let response = await client.cartControllerNewCart()
      basketToken = response.data.token
      Cookies.set('basket-token', response.data.token)
    }

    await this.CartApi.cartControllerPutProductCart({
      productId: productId,
      quantity: quantity
    }, basketToken as string)
  }

  async addProductToCustomerBasket(productId: number, quantity: number): Promise<void> {
    await this.CustomerApi.customerControllerPutCustomerCartProduct({
      productId: productId,
      quantity: quantity
    })
  }

  async onCreateAccount(account: AccountCreate): Promise<{success: boolean, error?: string}> {
    try {
      await this.CustomerApi.customerControllerCreateCustomer(account)
      return {success: true}
    } catch (error) {
      return {success: false, error: error.response.data.message[0]}
    }
  }

  async onLogin(login: AccountLogin): Promise<{success: boolean, error?: string}> {
    try {
      const response = await this.AuthApi.authControllerLogin(login)
      Cookies.set('jwt-token', response.data.accessToken)
      
      // If there is a baske token, try to affect logged user on the cart
      const basketToken = Cookies.get('basket-token')
      if (basketToken !== undefined) {
        await this.CartApi.cartControllerPutCustomer(basketToken, {headers : {
          'Authorization': 'Bearer '+response.data.accessToken
        }})
        Cookies.remove('basket-token')
      }

      return {success: true}
    } catch (error) {
      return {success: false, error: error.response.data.message[0]}
    }
  }

  async confirmEmail(token: string): Promise<{success: boolean, error?: string}> {
    try {
      this.CustomerApi.customerControllerPutCustomerActive(token)
      return {success: true}
    } catch (error) {
      return {success: false, error: error.response.data.message[0]}
    }
  }

  async imLogged(): Promise<boolean> {
    let token = Cookies.get('jwt-token')
    if (!token) return false
    try {
      await this.AuthApi.authControllerGetProfile()
      return true
    } catch (error) {
      return false
    }
  }

  async getDeliveryAddress(): Promise<DeliveryAddress> {
    const address = await this.CustomerApi.customerControllerGetCustomerAddress()
    return address.data
  }

  async onPostDeliveryAddress(address: DeliveryAddress): Promise<{success: boolean, error?: string}>  {
    try {
      await this.CustomerApi.customerControllerPostCustomerAddress(address)
      return {success: true}
    } catch (error) {
      return {success: false, error: error.response.data.message[0]}
    }
  }

  async getCountries(): Promise<Country[]> {
    const countries = await this.RessourceApi.ressourceControllerGetCountries()
    return countries.data
  }

  async getDeliveryOptions(): Promise<DeliveryOptions[]> {
    const options = await this.CustomerApi.customerControllerGetCartCarriers()
    return options.data
  }

  async putDeliveryOptions(carrierId: number): Promise<void> {
    await this.CustomerApi.customerControllerPutCarrier(carrierId.toString())
  }

  async basketCheckout(): Promise<void> {
    await this.CustomerApi.customerControllerPutCustomerCartCheckout()
  }

  async basketOrdered(): Promise<void> {
    await this.CustomerApi.customerControllerPutCustomerCartPaid()
    let orders = await this.getOrders()
    
  }

  async getOrders(): Promise<{reference: string, totalPaid: number, state: string}[]> {
    let orders = await this.CustomerApi.customerControllerGetCustomerOrders()
    return orders.data.map(order => ({
      ...order,
      totalPaid: parseFloat(order.totalPaid || (order as any).total_paid)
    }))
  }

  async putCustomerInfo(info: CustomerInfo): Promise<void> {
    sessionStorage.setItem(`basket-message`, JSON.stringify(info))
  }

  async getCustomerInfo(): Promise<CustomerInfo | null>  {
    let data = sessionStorage.getItem(`basket-message`)
    if (data == null) return null
    return JSON.parse(data)
  }

  async putOrderMessage(orderId: number, info: CustomerInfo): Promise<void>  {
    await this.CustomerApi.customerControllerPutCustomerOrderMessage({message: stringInfo(info)}, ''+orderId)
  }

}

const promoMap = {'RCS1': 16.0, 'RCS2': 16.0, 'RCS3': 16.0, 'RCS4': 16.0, 'RCS5': 13.0, 'RCS6': 13.0, 'RCS7': 13.0, 'RCS8': 13.0, 'RCS9': 13.0, 'RCS10': 14.0, 'RCS11': 14.0, 'RCS12': 14.0, 'RCS13': 14.0, 'RCS14': 14.0, 'RCS15': 14.0, 'RCS19': 14.0, 'RCS20': 14.0, 'RCS21': 14.0, 'RCS22': 14.0, 'RCS23': 15.0, 'RCS24': 15.0, 'RCS26': 15.0, 'RCS27': 15.0, 'BODPAP1': 7.5, 'BODPAP2': 7.5, 'BODPAP3': 7.5, 'BODPAP4': 7.5, 'BODPAP5': 7.5, 'BODPAP6': 7.5, 'BODPAP7A': 7.5, 'BODPAP7B': 7.5, 'BODPAP8': 7.5, 'BODPAP9A': 7.5, 'BODPAP9B': 7.5, 'BODPAP10': 7.5, 'BODPAP11': 7.5, 'BODPAP12': 7.5, 'BODPAP13': 7.5, 'BODPAP14': 7.5, 'BODAR1': 8.0, 'BODAR2': 8.0, 'BODAR3': 8.0, 'BODFR1': 8.0, 'BODFR2': 8.0, 'BODFR4': 8.0, 'BODO1': 8.0, 'BODO3': 8.0, 'BODO5': 8.0, 'BODO6': 8.0, 'BODFU1': 8.0, 'BODFU2': 8.0, 'BODTO': 7.5, 'BODM': 7.5, 'BODAN2': 8.0, 'BODH1': 8.0, 'BODH2': 8.0, 'BODH4': 8.0, 'BODH5': 8.0, 'BODH8': 8.0, 'BODH9': 8.0, 'BODH10': 8.0, 'BODH12': 8.0, 'RCN1': 18.0, 'RCN2': 18.0, 'RCN3': 18.0, 'RCN4': 18.0, 'COSAUG1': 28.0, 'COSAUG2': 28.0, 'COSAUG3': 28.0, 'COSAUM1': 26.0, 'COSAUM2': 26.0, 'COO1': 38.0, 'PAAFD3': 45.0, 'PAT1': 42.0, 'BRB4': 28.0, 'BRLAC2': 13.0, 'BRT4': 19.0, 'BRT5': 19.0, 'BRAC1': 23.0, 'CC2': 20.0, 'CC3': 20.0, 'BODC2': 19.0, 'BODC4': 23.0, 'BOB2': 17.0, 'BOB3': 17.0, 'BOCRL1': 24.0, 'BOCRL2': 24.0, 'BOCRL3': 29.0, 'BOPPL4': 14.0, 'BOPPL7': 17.0, 'BOPPL8': 17.0, 'BOPP4': 13.5, 'BOCN1': 15.0, 'BOCN2': 15.0, 'BOCN3': 15.0, 'BOCN5': 15.0, 'BOCN6': 15.0, 'BOC1': 12.0, 'BOC3': 12.0, 'BOSA2': 14.0, 'BOSA1': 14.0, 'BOV1': 13.0}

let mapProduct = (product: any) => ({
  id: product.id,
  reference: product.reference,
  name: product.name,
  descriptionHTML: product.descriptionHTML,
  slug: product.slug,
  images: product.otherPictures,
  coverImage: product.coverPicture,
  currencySymbol: product.currencySymbol,
  priceWithTax: product.priceWithTaxes,
  priceWithTaxFormat: product.priceWithTaxesFormat,
  stockAvailable: product.stockAvailable,
  priceBeforePromo: (promoMap as any)[product.reference.replace(/\s/g, '').toUpperCase()] as number
})

let mapCategory = (category: any) => ({
  node : {
    id: category.id,
    label: category.name,
    slug: category.slug,
    htmlDescription: category.description
  }, 
  children: category.subCategories.map(mapCategory)
})

let mapBasket = (basket: any): Basket => ({
  id: basket.id,
  deliveryAddressId: basket.deliveryAddressId,
  carrierId: basket.carrierId,
  priceTotalFormated: basket.totalCartFormated,
  priceTotalDeliveryFormated: basket.totalDeliveryFormated,
  priceTotalProductFormated: basket.totalProductFormated,
  products: (basket.products || []).map((p: any) => ({quantity: p.quantity, product: mapProduct(p.product)})),
  checkout_data: basket.checkout_data,
  checkout_status: basket.checkout_status,
  order: basket.order,
})

const stringInfo = (info: CustomerInfo) => `
    cheville: ${info.cheville}
    cou: ${info.cou}
    poignet: ${info.poignet}
    message: ${info.message}
`
