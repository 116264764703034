// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-category-debug-[slug]-tsx": () => import("./../../../src/pages/category-debug/[slug].tsx" /* webpackChunkName: "component---src-pages-category-debug-[slug]-tsx" */),
  "component---src-pages-cgv-tsx": () => import("./../../../src/pages/cgv.tsx" /* webpackChunkName: "component---src-pages-cgv-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-emailvalidation-index-tsx": () => import("./../../../src/pages/emailvalidation/index.tsx" /* webpackChunkName: "component---src-pages-emailvalidation-index-tsx" */),
  "component---src-pages-livraison-tsx": () => import("./../../../src/pages/livraison.tsx" /* webpackChunkName: "component---src-pages-livraison-tsx" */),
  "component---src-pages-product-[slug]-tsx": () => import("./../../../src/pages/product/[slug].tsx" /* webpackChunkName: "component---src-pages-product-[slug]-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-templates-blogpage-tsx": () => import("./../../../src/templates/blogpage.tsx" /* webpackChunkName: "component---src-templates-blogpage-tsx" */),
  "component---src-templates-categorypage-tsx": () => import("./../../../src/templates/categorypage.tsx" /* webpackChunkName: "component---src-templates-categorypage-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

