import * as actionCreators from "./actionCreators"
import {combineReducers} from "redux";
import { Basket, CustomerInfo, DeliveryAddress, DeliveryOptions, Product } from "../domain/interfaces";


const step = (state: 'setup' | 'address' | 'carrier' | 'payment' | 'payed' | 'canceled' = 'setup', action: actionCreators.Actions) => {
    if (action.type == 'CHECKOUT_BASKET_CONFIRM') {
        return 'address'
    }
    if (action.type == 'CHECKOUT_ADDRESS_POSTED') {
        return 'carrier'
    }
    if (action.type == 'CHECKOUT_DELIVERY_OPTION_SELECTED') {
        return 'payment'
    }
    if (action.type == 'CHECKOUT_PAYMENT_SUCCESS') {
        return 'payed'
    }
    if (action.type == 'CHECKOUT_PAYMENT_FAILED') {
        return 'canceled'
    }
    return state
}

const status = (state: 'loading' | 'success' = 'loading', action: actionCreators.Actions) => {
    if (["CHECKOUT_ADDRESS_FETCHED", "CHECKOUT_ADDRESS_POSTED", "CHECKOUT_DELIVERY_OPTION_SELECTED", "CHECKOUT_PAYMENT_SUCCESS"].indexOf(action.type) > -1) {
        return 'success'
    } else if (action.type == 'CHECKOUT_PROCESSING') {
        return 'loading'
    } else if (action.type == 'CHECKOUT_ERROR') {
        return 'error'
    }
    else {
        return state
    }
}

const error = (state: string | null = null, action: actionCreators.Actions) => {
    if (action.type == 'CHECKOUT_ERROR')
        return action.payload
    else
        return state
}

const basket = (basket: Basket | null = null, action: actionCreators.Actions) => {
    if (action.type == 'CHECKOUT_BASKET_FETCHED')
        return action.payload
    else
        return basket
}

const address = (address: Partial<DeliveryAddress> = {}, action: actionCreators.Actions) => {
    if (action.type === "CHECKOUT_ADDRESS_FETCHED")
        return action.payload
    else
        return address
}

const deliveryOptions = (options: DeliveryOptions[] = [], action: actionCreators.Actions) => {
    if (action.type === "CHECKOUT_DELIVERY_OPTIONS_FETCHED") {
        return action.payload
    }
    return options
}

const carrierName = (state: string | null = null, action: actionCreators.Actions) => {
    if (action.type === "CHECKOUT_DELIVERY_OPTION_SELECTED") {
        return action.payload
    }
    return state
}

const widgetPaymentStatus = (state: 'idle' | 'mounting' | 'mounted' = 'idle', action: actionCreators.Actions) => {
    if (action.type === 'CHECKOUT_MOUNT_PAYMENT_WIDGET') {
        return 'mounting'
    }
    if (action.type === 'CHECKOUT_PAYMENT_WIDGET_MOUNTED') {
        return 'mounted'
    }
    if (action.type === 'CHECKOUT_PAYMENT_WIDGET_DESTROYED'){
        return 'idle'
    }
    return state
}

const destroyPaymentWidgetCallback = (state: () => void = () => {}, action: actionCreators.Actions) => {
    if (action.type === 'CHECKOUT_MOUNT_PAYMENT_WIDGET') {
        return action.payload
    }
    return state
}

const info = (state: CustomerInfo | null = null, action: actionCreators.Actions) => {
    if (action.type == 'CHECKOUT_INFO_CUSTOMER_FETCHED') {
        return action.payload
    }
    return state
}

export default combineReducers({
    step,
    status,
    error,
    basket,
    info,
    address,
    carrierName,
    deliveryOptions,
    widgetPaymentStatus,
    destroyPaymentWidgetCallback
});
