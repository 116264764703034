/* tslint:disable */
/* eslint-disable */
/**
 * Eshop API
 * The eshop API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AddressDTO } from '../models';
import { AddressView } from '../models';
import { CarrierOptionView } from '../models';
import { CartView } from '../models';
import { CreateCustomerDTO } from '../models';
import { Order } from '../models';
import { PutOrderMessageDTO } from '../models';
import { TokenView } from '../models';
import { UpdateCartProductDto } from '../models';
/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCustomerDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerCreateCustomer: async (body: CreateCustomerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling customerControllerCreateCustomer.');
            }
            const localVarPath = `/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCartCarriers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/cart/carriers_options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomerAddress: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomerCart: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomerCartId: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling customerControllerGetCustomerCartId.');
            }
            const localVarPath = `/customer/cart/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomerOrders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddressDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPostCustomerAddress: async (body: AddressDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling customerControllerPostCustomerAddress.');
            }
            const localVarPath = `/customer/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} carrierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPutCarrier: async (carrierId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'carrierId' is not null or undefined
            if (carrierId === null || carrierId === undefined) {
                throw new RequiredError('carrierId','Required parameter carrierId was null or undefined when calling customerControllerPutCarrier.');
            }
            const localVarPath = `/customer/cart/carrier/{carrierId}`
                .replace(`{${"carrierId"}}`, encodeURIComponent(String(carrierId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPutCustomerActive: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling customerControllerPutCustomerActive.');
            }
            const localVarPath = `/customer/{token}/active`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPutCustomerCartCheckout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/cart/checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPutCustomerCartPaid: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/cart/paid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCartProductDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPutCustomerCartProduct: async (body: UpdateCartProductDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling customerControllerPutCustomerCartProduct.');
            }
            const localVarPath = `/customer/cart/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutOrderMessageDTO} body 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPutCustomerOrderMessage: async (body: PutOrderMessageDTO, orderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling customerControllerPutCustomerOrderMessage.');
            }
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling customerControllerPutCustomerOrderMessage.');
            }
            const localVarPath = `/customer/orders/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerTestMailCart: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/test/mail/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCustomerDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerCreateCustomer(body: CreateCustomerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartView>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerCreateCustomer(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetCartCarriers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarrierOptionView>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerGetCartCarriers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetCustomerAddress(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressView>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerGetCustomerAddress(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetCustomerCart(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartView>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerGetCustomerCart(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetCustomerCartId(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartView>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerGetCustomerCartId(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerGetCustomerOrders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerGetCustomerOrders(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AddressDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerPostCustomerAddress(body: AddressDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerPostCustomerAddress(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} carrierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerPutCarrier(carrierId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerPutCarrier(carrierId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerPutCustomerActive(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerPutCustomerActive(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerPutCustomerCartCheckout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerPutCustomerCartCheckout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerPutCustomerCartPaid(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerPutCustomerCartPaid(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateCartProductDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerPutCustomerCartProduct(body: UpdateCartProductDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerPutCustomerCartProduct(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {PutOrderMessageDTO} body 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerPutCustomerOrderMessage(body: PutOrderMessageDTO, orderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerPutCustomerOrderMessage(body, orderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerControllerTestMailCart(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerApiAxiosParamCreator(configuration).customerControllerTestMailCart(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateCustomerDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerCreateCustomer(body: CreateCustomerDTO, options?: any): AxiosPromise<CartView> {
            return CustomerApiFp(configuration).customerControllerCreateCustomer(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCartCarriers(options?: any): AxiosPromise<Array<CarrierOptionView>> {
            return CustomerApiFp(configuration).customerControllerGetCartCarriers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomerAddress(options?: any): AxiosPromise<AddressView> {
            return CustomerApiFp(configuration).customerControllerGetCustomerAddress(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomerCart(options?: any): AxiosPromise<CartView> {
            return CustomerApiFp(configuration).customerControllerGetCustomerCart(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomerCartId(id: string, options?: any): AxiosPromise<CartView> {
            return CustomerApiFp(configuration).customerControllerGetCustomerCartId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerGetCustomerOrders(options?: any): AxiosPromise<Array<Order>> {
            return CustomerApiFp(configuration).customerControllerGetCustomerOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddressDTO} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPostCustomerAddress(body: AddressDTO, options?: any): AxiosPromise<void> {
            return CustomerApiFp(configuration).customerControllerPostCustomerAddress(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} carrierId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPutCarrier(carrierId: string, options?: any): AxiosPromise<void> {
            return CustomerApiFp(configuration).customerControllerPutCarrier(carrierId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPutCustomerActive(token: string, options?: any): AxiosPromise<void> {
            return CustomerApiFp(configuration).customerControllerPutCustomerActive(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPutCustomerCartCheckout(options?: any): AxiosPromise<void> {
            return CustomerApiFp(configuration).customerControllerPutCustomerCartCheckout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPutCustomerCartPaid(options?: any): AxiosPromise<void> {
            return CustomerApiFp(configuration).customerControllerPutCustomerCartPaid(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCartProductDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPutCustomerCartProduct(body: UpdateCartProductDto, options?: any): AxiosPromise<void> {
            return CustomerApiFp(configuration).customerControllerPutCustomerCartProduct(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PutOrderMessageDTO} body 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerPutCustomerOrderMessage(body: PutOrderMessageDTO, orderId: string, options?: any): AxiosPromise<void> {
            return CustomerApiFp(configuration).customerControllerPutCustomerOrderMessage(body, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerControllerTestMailCart(options?: any): AxiosPromise<void> {
            return CustomerApiFp(configuration).customerControllerTestMailCart(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @param {CreateCustomerDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerCreateCustomer(body: CreateCustomerDTO, options?: any) {
        return CustomerApiFp(this.configuration).customerControllerCreateCustomer(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerGetCartCarriers(options?: any) {
        return CustomerApiFp(this.configuration).customerControllerGetCartCarriers(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerGetCustomerAddress(options?: any) {
        return CustomerApiFp(this.configuration).customerControllerGetCustomerAddress(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerGetCustomerCart(options?: any) {
        return CustomerApiFp(this.configuration).customerControllerGetCustomerCart(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerGetCustomerCartId(id: string, options?: any) {
        return CustomerApiFp(this.configuration).customerControllerGetCustomerCartId(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerGetCustomerOrders(options?: any) {
        return CustomerApiFp(this.configuration).customerControllerGetCustomerOrders(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {AddressDTO} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerPostCustomerAddress(body: AddressDTO, options?: any) {
        return CustomerApiFp(this.configuration).customerControllerPostCustomerAddress(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} carrierId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerPutCarrier(carrierId: string, options?: any) {
        return CustomerApiFp(this.configuration).customerControllerPutCarrier(carrierId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerPutCustomerActive(token: string, options?: any) {
        return CustomerApiFp(this.configuration).customerControllerPutCustomerActive(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerPutCustomerCartCheckout(options?: any) {
        return CustomerApiFp(this.configuration).customerControllerPutCustomerCartCheckout(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerPutCustomerCartPaid(options?: any) {
        return CustomerApiFp(this.configuration).customerControllerPutCustomerCartPaid(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {UpdateCartProductDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerPutCustomerCartProduct(body: UpdateCartProductDto, options?: any) {
        return CustomerApiFp(this.configuration).customerControllerPutCustomerCartProduct(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {PutOrderMessageDTO} body 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerPutCustomerOrderMessage(body: PutOrderMessageDTO, orderId: string, options?: any) {
        return CustomerApiFp(this.configuration).customerControllerPutCustomerOrderMessage(body, orderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerControllerTestMailCart(options?: any) {
        return CustomerApiFp(this.configuration).customerControllerTestMailCart(options).then((request) => request(this.axios, this.basePath));
    }
}
