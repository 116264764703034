import {Action, applyMiddleware, combineReducers, Store, createStore} from "redux";
import basket from "./basket.reducer";
import checkout from "./checkout.reducer";
import thunk, {ThunkAction, ThunkDispatch, ThunkMiddleware} from "redux-thunk";
import {Dependencies} from "./dependencies";
import {ShopState} from "./state.interface";
import {composeWithDevTools} from "redux-devtools-extension/index";

export const configureStore = (dependencies: Dependencies | null) =>
    createStore(
        combineReducers({
            basket,
            checkout
        }), composeWithDevTools(applyMiddleware(dependencies ?
            thunk.withExtraArgument(dependencies) as ThunkMiddleware<ShopState, Action, Dependencies> :
            thunk)));

export type ReduxStore = Store<ShopState> & { dispatch: ThunkDispatch<ShopState, Dependencies, Action> }
export type ThunkResult<R> = ThunkAction<R, ShopState, Dependencies, Action>;
