import { Basket, CustomerInfo, DeliveryAddress, DeliveryOptions } from "../domain/interfaces";
import {ActionsUnion, createAction} from "./customAction";

export const BASKET_FETCHED = 'BASKET_FETCHED'
export const DISPLAY_BASKET = 'DISPLAY_BASKET'
export const LOADING_BASKET = 'LOADING_BASKET'
export const BASKET_ERROR = 'BASKET_ERROR'

export const CHECKOUT_ERROR = "CHECKOUT_ERROR"
export const CHECKOUT_BASKET_FETCHED = "CHECKOUT_BASKET_FETCHED"
export const CHECKOUT_ADDRESS_FETCHED = 'CHECKOUT_ADDRESS_FETCHED'
export const CHECKOUT_INFO_CUSTOMER_FETCHED = 'CHECKOUT_INFO_CUSTOMER_FETCHED'
export const CHECKOUT_PROCESSING = 'CHECKOUT_PROCESSING'
export const CHECKOUT_BASKET_CONFIRM = 'CHECKOUT_BASKET_CONFIRM'
export const CHECKOUT_ADDRESS_POSTED = 'CHECKOUT_ADDRESS_POSTED'
export const CHECKOUT_DELIVERY_OPTIONS_FETCHED = 'CHECKOUT_DELIVERY_OPTIONS_FETCHED'
export const CHECKOUT_DELIVERY_OPTIONS_SELECTED = 'CHECKOUT_DELIVERY_OPTION_SELECTED'
export const CHECKOUT_PAYMENT_SUCCESS = 'CHECKOUT_PAYMENT_SUCCESS'
export const CHECKOUT_PAYMENT_FAILED = 'CHECKOUT_PAYMENT_FAILED'
export const CHECKOUT_MOUNT_PAYMENT_WIDGET = 'CHECKOUT_MOUNT_PAYMENT_WIDGET'
export const CHECKOUT_PAYMENT_WIDGET_MOUNTED = 'CHECKOUT_PAYMENT_WIDGET_MOUNTED'
export const CHECKOUT_PAYMENT_WIDGET_DESTROYED = 'CHECKOUT_PAYMENT_WIDGET_DESTROYED'


export const Actions = {

    loadingBasket: (value: boolean) =>
        createAction(LOADING_BASKET, value),
    displayBasket: (value: boolean) =>
        createAction(DISPLAY_BASKET, value),
    basketFetched: (basket: Basket) =>
        createAction(BASKET_FETCHED, basket),
    basketError: (value: string) =>
        createAction(BASKET_ERROR, value),
    
    checkoutProcessing: () =>
        createAction(CHECKOUT_PROCESSING),
    checkoutError: (error: string) => 
        createAction(CHECKOUT_ERROR, error),
    checkoutCustomerInfoFetched: (info: CustomerInfo) =>
        createAction(CHECKOUT_INFO_CUSTOMER_FETCHED, info),
    checkoutBasketFetched: (basket: Basket) => 
        createAction(CHECKOUT_BASKET_FETCHED, basket),
    checkoutAddressFetched: (address: DeliveryAddress) =>
        createAction(CHECKOUT_ADDRESS_FETCHED, address),
    checkoutBasketConfirm: () =>
        createAction(CHECKOUT_BASKET_CONFIRM),
    checkoutAddressPosted: (address: DeliveryAddress) =>
        createAction(CHECKOUT_ADDRESS_POSTED, address),
    checkoutDeliveryOptionsFetched: (options: DeliveryOptions[]) =>
        createAction(CHECKOUT_DELIVERY_OPTIONS_FETCHED, options),
    checkoutDeliveryOptionsSelected: (carrierName: string) => 
        createAction(CHECKOUT_DELIVERY_OPTIONS_SELECTED, carrierName),
    checkoutPaymentSuccess: () =>
        createAction(CHECKOUT_PAYMENT_SUCCESS),
    checkoutPaymentFailed: () =>
        createAction(CHECKOUT_PAYMENT_FAILED),
    checkoutMountPaymentWidget: (destroy: () => void) =>
        createAction(CHECKOUT_MOUNT_PAYMENT_WIDGET, destroy),
    checkoutPaymentWidgetMounted: () =>
        createAction(CHECKOUT_PAYMENT_WIDGET_MOUNTED),
    checkoutPaymentWidgetDestroy: () =>
        createAction(CHECKOUT_PAYMENT_WIDGET_DESTROYED)
};

export type Actions = ActionsUnion<typeof Actions>;
