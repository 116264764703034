import * as actionCreators from "../state/actionCreators";
import {ThunkResult} from "../state/configureStore";


export const refreshBasket: ThunkResult<Promise<void>> = async (dispatch, state, {apiGateways}) => {
    dispatch(actionCreators.Actions.loadingBasket(true))
    let basket = await apiGateways.getBasket()
    dispatch(actionCreators.Actions.basketFetched(basket))
    dispatch(actionCreators.Actions.loadingBasket(false))
    dispatch(actionCreators.Actions.basketError(""))
}

export const addProductToBasket = (productId: number): ThunkResult<Promise<void>>  => async (dispatch, state, {apiGateways}) => {
    dispatch(actionCreators.Actions.basketError(""))
    dispatch(actionCreators.Actions.loadingBasket(true))
    let isProductInBasket = state().basket.products.find(product => product.product.id == productId)
   
    let quantity = 1
    if (isProductInBasket) {
        quantity = isProductInBasket.quantity + 1
    }

    try {
        await apiGateways.addProductToBasket(productId, quantity)
    } catch (error) {
        if (error.response.data.error == "PRODUCT_OUT_OF_STOCK") {
            dispatch(actionCreators.Actions.basketError(`Vous ne pouvez pas ajouter plus de ${quantity} article(s) de cette référence dans le panier, le stock disponible est insuffisant`))
        }
    }
    dispatch(actionCreators.Actions.displayBasket(true))
    let basket = await apiGateways.getBasket()
    dispatch(actionCreators.Actions.basketFetched(basket))
    dispatch(actionCreators.Actions.loadingBasket(false))
}

export const removeProductFromBasket = (productId: number): ThunkResult<Promise<void>>  => async (dispatch, state, {apiGateways}) => {
    dispatch(actionCreators.Actions.loadingBasket(true))
    let isProductInBasket = state().basket.products.find(product => product.product.id == productId)
    if (isProductInBasket) {
        await apiGateways.addProductToBasket(productId, isProductInBasket.quantity - 1)
    } 
    dispatch(actionCreators.Actions.displayBasket(true))
    let basket = await apiGateways.getBasket()
    dispatch(actionCreators.Actions.basketFetched(basket))
    dispatch(actionCreators.Actions.loadingBasket(false))
}
